.home-text{
  font-family: var(--font-family-base), var(--font-family-fallback);
  color: var(--c-text);
}

.home-text tbody tr:hover{
  color: var(--c-text);
}

a:hover {
  color: var(--c-primary);
  text-decoration: underline;
  }

.btn-c-primary {
  color: var(--c-primary-text);
  background-color: var(--c-primary);
  border: var(--c-primary);
}

.btn-c-primary:hover, .btn-c-primary:focus, .btn-c-primary:active, .btn-c-primary.active, .show> .btn-c-primary.dropdown-toggle, .show> .btn-c-primary.dropdown-toggle:active{
  color: var(--c-primary-text);
  background-color: var(--c-primary-active);
  border: var(--c-primary-active);
}

.dropdown-item.active.c-primary {
  background-color: var(--c-primary-active);
}

.dropdown-item.c-primary:focus {
  background-color: var(--c-primary-active);
}

.btn-c-secondary {
  color: var(--c-secondary-text);
  background-color: var(--c-secondary);
  border: var(--c-secondary);
}

.btn-c-secondary:hover, .btn-c-secondary:focus, .btn-c-secondary:active, .btn-c-secondary.active, .show>.btn-c-secondary.dropdown-toggle{
  color: var(--c-secondary-text);
  background-color: var(--c-secondary-active);
  border: var(--c-secondary-active);
}

.wrapper {
  min-height: calc(100vh - #{$footer-height});
}

.bg-ship {
  background-color: var(--bg-secondary);
  background-size: cover;
}

.form-control {
  border-color:var(--bg-secondary);
}

.form-control:focus{
  border-color: var(--c-primary);
}
.card-primary {
  border-top: 3px solid var(--c-primary);
  background-color: var(--bg-primary);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-base;
  color: var(--c-text);
}

.clear-button-styles-primary {
  background-color: transparent;
  border: none;
  color: var(--c-primary);
}

.page-item.active .page-c-link {
  z-index: 3;
  color: var(--c-primary-text);
  background-color: var(--c-primary);
  border-color: var(--c-primary);
  }

.page-c-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--c-primary);
  background-color: var(--bg-primary);
  border: 1px solid #dee2e6;
  }

.page-c-link:focus {
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.label-flex {
  display:flex;
  align-items: center;
  margin-bottom: 5px;
  .form-check-input {
    margin-top: 0;
  }
}

.table-row {
  @media (max-width: 1200px) {
    flex-direction: column;
  }
}
.table-buttons {
  @media (max-width: 1200px) {
    justify-content: space-between !important;
    margin-left: 0;
    margin-top: 12px;
    max-width: unset;
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.table-form-buttons {
  @media (max-width: 992px) {
    margin-top: 12px;
  }
}