$primary: #1e1c77;
$secondary: #8c7f70;
$primary-lighter: #C9CEDE;
$secondary-lighter: lighten($secondary, 45%);
$font-family-base: 'Roboto';
$font-weight-base: 300;
$footer-height: 40px;

:root{
    --c-text:#212529;
    --bg-primary: #{primary-lighter};
    --bg-secondary: #{$secondary-lighter};

    --c-primary: #{primary};
    --c-primary-active: #161558;
    --c-primary-text: #fff;

    --c-secondary: #{secondary};
    --c-secondary-active: #847849;
    --c-secondary-text: #fff;

    --font-family-base: #{font-family-base};
    --font-family-fallback: 'sans-serif'
}